
<script>
export default {
  data() {
    return {
      works: [
        { id: "1", name: "原神签到工具", href: "https://ys.xinit.xyz" },
        {
          id: "2",
          name: "角度计算器",
          href: "https://github.com/imshixin/angel-calcor",
        },
        {
          id: "2",
          name: "小爱课程表学校适配",
          href: "https://github.com/imshixin/AiSchedule",
        },
        { id: "3", name: "开发中", href: "#" },
      ],
      timer: null,
    };
  },
};
</script>


<template>
  <div class="container">
    <ul class="workList">
      <li v-for="work in works" :key="work.id" class="workItem">
        <a :href="work.href">{{ work.name }} </a>
        <!-- <span
          class="iconfont icon-tishi"
          :index="ind"
          @click="$emit('rotateCard')"
        ></span> -->
      </li>
    <div class="workItem">
      <a target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51062302000162"
        style=" text-decoration: none; "
        ><img src="" style="float: left" />
        <p style=" color: #222; margin:0" >
          川公网安备 51062302000162号
        </p>
      </a>
    </div>
    </ul>
  </div>
</template>

<style lang='scss' scoped>
$bg-color: rgb(204, 204, 255, 0.4);
$item-color: rgba(220, 220, 255, 0.753);
$item-border-color: #66cccc;
$hover-time: 0.3s;
$text-color: #333333;
$border-rad: 8px;
$item-rad: 4px;
.container {
  background-color: $bg-color;
  padding-top: 1px;
  padding-bottom: 10px;
  border-radius: $border-rad;
  margin: 8px 8px;
  position: absolute;
  top: 131px;
  right: 0;
  bottom: 0;
  left: 0;
  &::before {
    content: "作品列表";
    display: block;
    padding: 4px 10px;
    margin: 14px 10px 0;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.294);
    border-left: 4px solid rgba(151, 151, 255, 1);
    border-radius: $item-rad/2;
    // border-top-right-radius: 20px;
    // border-bottom-right-radius: 20px;
  }
  .workList {
    list-style: none;
    padding-left: 0;
    // margin-top: 12px;
    perspective: 600px;
    .workItem {
      float: left;
      height: 36px;
      margin: 0 10px 8px;
      padding-right: 10px;
      border-top: 5px solid $item-border-color;
      border-radius: $item-rad;
      background-color: $item-color;
      transition: background-color $hover-time ease-in,
        border-color $hover-time ease-in, box-shadow $hover-time ease-in,
        transform $hover-time ease-in;
      &:hover {
        border-color: $item-border-color;
        border-top-color: #d4d4ff;
        background-color: $item-border-color;
        transform: translateZ(6px);
        a {
          color: white;
        }
      }
      a {
        display: inline-block;
        color: $text-color;
        transition: color $hover-time ease-in;
        padding: 0 0px 0px 10px;
        text-decoration: none;
        font-size: 16px;
        line-height: 31px;
      }
      .icon-tishi {
        cursor: pointer;
        line-height: 31px;
        font-size: 14px;
        border-radius: 50%;
        margin-left: 3px;
        padding: 3px;
        transition: color $hover-time ease-in,
          background-color $hover-time ease-in;
        background-color: $bg-color;
        &:hover {
          background-color: white;
          color: $item-border-color;
        }
      }
    }
    &::after {
      color: $text-color;
      content: "";
      display: block;
      clear: both;
    }
  }
}
</style>
