<script>
export default {
  data(){
    return{
      blog:'https://blog.xinit.xyz',
      github:'https://github.com/imshixin',
      qq:'',
      bilibili:''
    }
  }
};
</script>

<template>
  <div class="container">
    <div class="headImg"></div>
    <div class="divider"></div>
    <div class="contact">
      <div class="top">
        <a :href="this.blog" target="__black" class="blog-link">&gt;&gt;我的博客</a>
      </div>
      <div class="bottom">
        <a :href="this.github" class="icon iconfont">&#xe61a;</a>
        <a :title="this.qq" class="icon iconfont">&#xe609;</a>
        <a :href="this.bilibili" class="icon iconfont">&#xe66e;</a>
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
$bg-color: rgb(204, 204, 255,.3);
$divider-color:#fff;
$border-rad: 18px;
$icon-size:36px;

.container {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  .headImg {
    width: 75px;
    height: 75px;
    // background-color: $bg-color;
    border-radius: 50%;
    background: no-repeat url('../assets/head.jpg') ;
    background-size: cover;
  }
  .divider {
    width: 1px;
    height: 65%;
    background-color: $bg-color;
  }
  .contact {
    width: 180px;
    height: 100px;
    text-align: center;
    .top {
      height: 50%;
      .blog-link {
        color: white;
        text-decoration: none;
        line-height: 50px;
        font-size: 24px;
        display: block;
      }
    }
    .bottom {
      height: 50%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .icon {
        text-decoration: none;
        // background-color: #fff;
        width: $icon-size;
        height: $icon-size;
        border-radius: 50%;
        line-height: $icon-size;
        font-size: $icon-size;
        &:hover{
          animation: icon-shake 1.8s ease-in-out 0s infinite ;
        }
      }
      .icon:nth-child(1){
        color:#000000;
      }
      .icon:nth-child(2){
        color:#30a5dd;
      }
      .icon:nth-child(3){
        color:#f08198;
      }
    }
  }
}
@keyframes icon-shake {
  // 0%{
  //   transform: rotate(0);
  // }
  22%{
    transform: rotate(-45deg);
  }
  // 44%{
  //     transform: rotate(0deg);
  // }
  66%{
    transform: rotate(45deg);
  }
  88%{
    transform: rotate(0);
  }
}
</style>
