<template>
<div class="container">
  <div class="before">Lorem, ipsum dolor.</div>

</div>
</template>

<script>
export default {

}
</script>

<style lang='scss' scoped>
$bg-color: rgb(204, 204, 255,.4);
$item-color: rgba(220, 220, 255, 0.753);
$item-border-color: #66cccc;
$hover-time:.3s;
$text-color:#333333;
$border-rad:4px;
.container{
  background-color: $bg-color;
  padding-top: 1px;
  border-radius: $border-rad;
  margin: 8px 8px;
  position: absolute;
  top: 131px;
  bottom: 0;
  left: 0;
  right: 0;
  .before{
    display: block;
    padding: 4px 10px ;
    margin: 6px 10px 0;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.294);
    border-left: 4px solid rgba(151, 151, 255, 1);
    border-radius: $border-rad/2;
    // border-top-right-radius: 20px;
    // border-bottom-right-radius: 20px;
  }

}
</style>