<template>
  <router-view></router-view>
</template>
<style lang="scss">

$bg-color:#66cccc;
$border-rad:18px;

*{
  box-sizing: border-box;
}

html,body{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
#app{
  width: 100%;
  height: 100%;
  // background: linear-gradient(-45deg,#ff5959,#3867ff,#ff38ff);
  // background: hsla(31, 90%, 76%, 1);
  background: linear-gradient(45deg, #40c9ff 0%, #e81cff 100%);
  background-size: 400% 400%;
  background-position: 0 50%;
  animation: bgcolor 18s linear 0s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes bgcolor {
  0%{
    background-position: 0 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0 50%;
  }
}
</style>
