<template>
  <div class="wrapper">
    <div :class="{ front: true, rotateF: state.rotate }">
      <app-header></app-header>

      <div class="divider"></div>
      <app-content
        @rotateCard="this.state.rotate = !this.state.rotate"
      ></app-content>
    </div>
    <div :class="{ backend: true, rotateB: state.rotate }">
      <app-header></app-header>
      <div class="divider"></div>
      <app-desc></app-desc>
    </div>

  </div>
</template>

<style lang="scss" scoped>
$bg-color: rgba(204, 204, 255, 0.506);
$border-rad: 8px;
$card-width: 320px;
$card-height: 500px;
.wrapper {
  width: $card-width;
  height: $card-height;
  border-radius: $border-rad;
  position: relative;
  transition: transform 0.8s ease-in;
  transform: rotateY(0deg);
  perspective: 1000px;
}

.front {
  @extend .wrapper;
  background-color: $bg-color;
  backface-visibility: hidden;
  box-shadow: 0px 0px 3px #c8c8c8;
  position: relative;
  &.rotateF {
    transform: rotateY(-180deg);
  }
  .divider {
    height: 1px;
    background-color: $bg-color;
    margin: 8px 16%;
    background: hsla(154, 100%, 76%, 1);
    background: linear-gradient(
      to right,
      hsla(154, 100%, 76%, 1) 0%,
      hsla(234, 100%, 83%, 1) 50%,
      hsla(288, 100%, 81%, 1) 100%
    );
  }
}
.backend {
  @extend .front;
  top: 0;
  left: 0;
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  &.rotateB {
    transform: rotateY(0);
  }
}
</style>

<script>
// @ is an alias to /src
import AppHeader from "@/components/AppHeader.vue";
import AppContent from "../components/AppContent.vue";
import AppDesc from "../components/AppDesc.vue";
import { reactive } from "vue";

const state = reactive({ rotate: false });
export default {
  name: "Home",
  data() {
    return {
      state,
    };
  },
  components: {
    AppHeader,
    AppContent,
    AppDesc,
  },
};
</script>
